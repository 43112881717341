import { Ellipsis } from 'react-bootstrap/esm/PageItem';
import './App.css';
import { useEffect, useState } from 'react';
let localdays = JSON.parse(localStorage.getItem('days'))
console.log('localdays', localdays)
let blockindex = 0;
let today = new Date();
const year = today.getFullYear();
const month = String(today.getMonth() + 1).padStart(2, '0');
const 날 = String(today.getDate()).padStart(2, '0');
const currentDate = `${year}-${month}-${날}`
var deviceHeight = 1.71 * document.documentElement.clientHeight;
let mode = ''

function App() {

  let [date, setDate] = useState(currentDate)
  let [title, setTitle] = useState('')
  let [memo, setMemo] = useState('')
  let [start, setStart] = useState('')
  let [end, setEnd] = useState('')
  let [hash, setHash] = useState('')
  let [box, setBox] = useState('')

  let [heightarray, setH] = useState([])
  let [marginarray, setM] = useState([])
  let [heightarray2, setH2] = useState([])
  let [marginarray2, setM2] = useState([])
  let [titlearray, setT] = useState([])
  let [titlearray2, setT2] = useState([])
  let [button, setButton] = useState('입력하기')

  let day = {
    id: '',
    plan: [],
    do: [],
    textbox: ''
  }

  // 시간표 블럭 생성하는 곳
  let divCount = 21;
  let divElements = Array.from({ length: divCount }, (_, index) => (
    <div key={index} style={{
      boxSizing: 'border-box', borderTop: '1px solid black', display: 'flex',
      justifyContent: 'center',
      alignItems: 'center', minHeight: deviceHeight / 24
    }}>
      {index + 3}
    </div>
  ));

  let divCount2 = 3;
  let divElements2 = Array.from({ length: divCount2 }, (_, index) => (
    <div key={index + 24} style={{
      boxSizing: 'border-box', borderTop: '1px solid black', display: 'flex',
      justifyContent: 'center',
      alignItems: 'center', minHeight: deviceHeight / 24
    }}>
      {index}
    </div>
  ));

  function calculate (parameter, H, M, T) {
    let array = parameter
    let harray = []
    let marray = []
    let title = []

    array.map((a) => {
      if (parseInt(a.start.split(":")[0], 10) > 3 && parseInt(a.end.split(":")[0], 10) < 3) {
        var height = ((parseInt(a.end.split(":")[0], 10) + 24 - parseInt(a.start.split(":")[0], 10)) * 60 + (parseInt(a.end.split(":")[1], 10) - parseInt(a.start.split(":")[1], 10))) * 0.06944
        var margin = deviceHeight * (((parseInt(a.start.split(":")[0], 10)) * 60 + (parseInt(a.start.split(":")[1], 10))) / 60 - 3) / 24
        if (margin < 0) {
          margin = deviceHeight * (((parseInt(a.start.split(":")[0], 10)) * 60 + (parseInt(a.start.split(":")[1], 10))) / 60 + 21) / 24
        }

        harray.push(height)
        marray.push(margin)
        title.push(a.title)
      }

      else {
        var height = ((parseInt(a.end.split(":")[0], 10) - parseInt(a.start.split(":")[0], 10)) * 60 + (parseInt(a.end.split(":")[1], 10) - parseInt(a.start.split(":")[1], 10))) * 0.06944
        var margin = deviceHeight * (((parseInt(a.start.split(":")[0], 10)) * 60 + (parseInt(a.start.split(":")[1], 10))) / 60 - 3) / 24
        if (margin < 0) {
          margin = deviceHeight * (((parseInt(a.start.split(":")[0], 10)) * 60 + (parseInt(a.start.split(":")[1], 10))) / 60 + 21) / 24
        }

        harray.push(height)
        marray.push(margin)
        title.push(a.title)
      }

      H(harray)
      M(marray)
      T(title)

    })
  }

  // 길이 계산후 state에 setstate
  function showplan(parameter) {
    console.log('parameter>', parameter)
    const value = parameter;
    setH([])
    setM([])
    setT([])
    

    if (localdays && Object.keys(localdays).some(key => { return (key == value) })) {
 
      calculate(localdays[value].plan, setH, setM, setT)

    }
  }

  // 길이 계산후 state에 setstate
  function showdo(parameter) {
    const value = parameter;
    setH2([])
    setM2([])
    setT2([])

    if (localdays && Object.keys(localdays).some(key => { return (key == value) })) {

      calculate(localdays[value].do, setH2, setM2, setT2)
    }
  }

  // array state를 받아서 div와 반복문을 활용해 화면에 표현, 삭제, 수정
  function MyComponentPlan() {
    const items = marginarray;
    const items2 = heightarray;
    const title = titlearray;

    return (
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>

        {Array.from({ length: 24 }, (_, index) => (
          <div key={index + 50} style={{
            boxSizing: 'border-box', display: 'flex', flexDirection: 'column',
            width: '100%', position: 'absolute', height: deviceHeight / 24, marginTop: index * deviceHeight / 24
          }} onClick={() => {
            // 초기화
            setTitle('')
            setMemo('')
            setStart('')
            setEnd('')
            mode = 'plan'
            // 값 집어넣기

            if (index < 20) {
              setStart((index + 3).toString().padStart(2, '0') + ':00')
              setEnd((index + 4).toString().padStart(2, '0') + ':00')
            }

            else if (20 == index) {
              setStart((index + 3).toString().padStart(2, '0') + ':00')
              setEnd((index - 20).toString().padStart(2, '0') + ':00')
            }

            else if (index > 20) {
              setStart((index - 21).toString().padStart(2, '0') + ':00')
              setEnd((index - 20).toString().padStart(2, '0') + ':00')
            }

            console.log(index)
            document.querySelector('.modal').classList.toggle('on-off')
          }}>
          </div>
        ))}

        {items.map((item, index) => (
          <div key={index}
            style={{
              marginTop: items[index],
              width: '100%',
              height: items2[index] + '%',
              backgroundColor: 'aquamarine',
              textAlign: 'center',
              position: 'absolute',
              boxSizing: 'border-box',
              border: '1px solid black',
              zIndex: 3,
              display: 'block',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}>
            {title[index]}
            <button
              className='del'
              id={index}
              onClick={() => {

                var result = window.confirm('삭제 하시겠습니까?');

                if (result) {
                  localdays[date].plan.splice(index, 1);
                  localStorage.setItem('days', JSON.stringify(localdays))
                  showplan(date)
                }
              }}
            >x</button>

            <button className='del' onClick={() => {

              mode = 'plan-modify'
              setButton('수정하기')
              document.querySelector('.modal').classList.toggle('on-off')
              setTitle(localdays[date].plan[index].title)
              setMemo(localdays[date].plan[index].memo)
              setStart(localdays[date].plan[index].start)
              setEnd(localdays[date].plan[index].end)
              setHash(localdays[date].plan[index].hash)
              blockindex = index;

            }}
            >수정</button>
          </div>

        ))}
      </div>
    );
  }

  // array state를 받아서 div와 반복문을 활용해 화면에 표현, 삭제, 수정
  function MyComponentDo() {
    const items = marginarray2;
    const items2 = heightarray2;
    const title = titlearray2;

    return (
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>

        {Array.from({ length: 24 }, (_, index) => (
          <div key={index + 50} style={{
            boxSizing: 'border-box', display: 'flex', flexDirection: 'column',
            width: '100%', position: 'absolute', height: deviceHeight / 24, marginTop: index * deviceHeight / 24
          }} onClick={() => {
            // 초기화
            setTitle('')
            setMemo('')
            setStart('')
            setEnd('')
            mode = 'do'
            // 값 집어넣기

            if (index < 20) {
              setStart((index + 3).toString().padStart(2, '0') + ':00')
              setEnd((index + 4).toString().padStart(2, '0') + ':00')
            }

            else if (20 == index) {
              setStart((index + 3).toString().padStart(2, '0') + ':00')
              setEnd((index - 20).toString().padStart(2, '0') + ':00')
            }

            else if (index > 20) {
              setStart((index - 21).toString().padStart(2, '0') + ':00')
              setEnd((index - 20).toString().padStart(2, '0') + ':00')
            }

            console.log(index)
            document.querySelector('.modal').classList.toggle('on-off')
          }}>
          </div>
        ))}

        {items.map((item, index) => (
          <div key={index}
            style={{
              border: '1px solid black',
              marginTop: items[index],
              width: '100%',
              height: items2[index] + '%',
              backgroundColor: 'yellow',
              textAlign: 'center',
              boxSizing: 'border-box',
              position: 'absolute',
              display: 'block',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}>
            {title[index]}
            <button className='del'
              id={index}
              onClick={() => {

                var result = window.confirm('삭제 하시겠습니까?');

                if (result) {
                  localdays[date].do.splice(index, 1);
                  localStorage.setItem('days', JSON.stringify(localdays))
                  showdo(date)
                }
              }}
            >x</button>
            <button className='del'
              onClick={() => {

                mode = 'do-modify'
                setButton('수정하기')
                document.querySelector('.modal').classList.toggle('on-off')
                setTitle(localdays[date].do[index].title)
                setMemo(localdays[date].do[index].memo)
                setStart(localdays[date].do[index].start)
                setEnd(localdays[date].do[index].end)
                setHash(localdays[date].do[index].hash)
                // 123123
                blockindex = index;

              }}
            >수정</button>
          </div>
        ))}
      </div>
    );
  }

  // 입력 모달창 세팅
  function plando() {
    setTitle('')
    setMemo('')
    setStart('')
    setEnd('')
    setButton('입력하기')
    document.querySelector('.modal').classList.toggle('on-off')
  }

  function abc(a, b, c) {

    day.id = date
    a.push(c)

    if (localStorage.getItem('days') == null) {
      const obj = {}
      obj[date] = day;
      localStorage.setItem('days', JSON.stringify(obj))
      localdays = obj;
    }

    else {

      if (localdays[date] == null) {
        localdays[date] = day;
        localStorage.setItem('days', JSON.stringify(localdays))
      }
      else {
        b.push(c)
        localStorage.setItem('days', JSON.stringify(localdays))
      }
    }
  }

  // 현재 날짜를 받아서 마운트할 때 한번만 실행
  useEffect(
    () => {
      console.log('컴포넌트가 마운트되었습니다.');
      showplan(date)
      showdo(date)
      if (localdays[date] == null) {
        setBox('')
      }
      else {
        setBox(localdays[date].textbox)
      }

      return () => {
        console.log('언마운트')

      }
    },
    []
  )

  return (
    <>
      <div className='input'>

        <input type='date'
          value={date}
          onChange={(e) => {
            setDate(e.target.value)
            showplan(e.target.value)
            showdo(e.target.value)
            if (localdays[e.target.value] == null) {
              setBox('')
            }
            else {
              setBox(localdays[e.target.value].textbox)
            }
          }}></input>

        <button onClick={() => {

          mode = 'plan'
          plando()

        }}>Plan</button>

        <button onClick={() => {

          mode = 'do'
          plando()

        }}>do</button>

      </div>

      <div className='container'>


        <div className='main'>

          <div className='top'>
            <div className='time'>시간</div>
            <div className='plando'>Plan</div>
            <div className='plando'>Do</div>
          </div>

          <div className='bottom'>

            <div style={{
              width: '10%',
              height: deviceHeight,
              backgroundColor: 'aqua',
              display: 'flex',
              flexDirection: 'column'
            }}>
              {divElements}
              {divElements2}
            </div>

            <div style={{
              width: '45%',
              height: deviceHeight,
              backgroundColor: 'darkseagreen',
              display: 'flex',
              flexDirection: 'column'
            }}>
              {MyComponentPlan()}
            </div>

            <div style={{
              width: '45%',
              height: deviceHeight,
              backgroundColor: 'darkseagreen',
              display: 'flex',
              flexDirection: 'column'
            }}>
              {MyComponentDo()}
            </div>

          </div>
        </div>
      </div>


      <div className='modal'>

        <div className='modal-xbutton'>
          <button className='button'
            onClick={() => { document.querySelector('.modal').classList.toggle('on-off') }}>
            x</button>
        </div>

        <div className='modal-input'>
          <input style={{ margin: '20px' }}
            type='text'
            placeholder='제목'
            value={title}
            onChange={(e) => { setTitle(e.target.value) }}>
          </input>

          <input type='time'
            value={start}
            onChange={(e) => { setStart(e.target.value) }}>
          </input>

          <input type='time'
            value={end}
            onChange={(e) => { setEnd(e.target.value) }}>
          </input>

          <input type='text'
            placeholder='메모'
            value={memo}
            onChange={(e) => { setMemo(e.target.value) }}>
          </input>

          <input type='text'
            placeholder='해쉬태그'
            value={hash}
            onChange={(e) => { setHash(e.target.value) }}>
          </input>

          <button onClick={() => {

            const task = {
              title: title,
              memo: memo,
              start: start,
              end: end,
              hash: hash
            }

            if (start < end) {
              if (mode == 'plan') {

                console.log('플랜')
                day.id = date
                day.plan.push(task)

                if (localStorage.getItem('days') == null) {
                  const obj = {}
                  obj[date] = day;
                  localStorage.setItem('days', JSON.stringify(obj))
                  localdays = obj;
                }

                else {

                  if (localdays[date] == null) {
                    localdays[date] = day;
                    localStorage.setItem('days', JSON.stringify(localdays))
                  }
                  else {
                    localdays[date].plan.push(task)
                    localStorage.setItem('days', JSON.stringify(localdays))
                  }
                }


                if (parseInt(task.start.split(":")[0], 10) > 3 && parseInt(task.end.split(":")[0], 10) < 3) {
                  var height = ((parseInt(task.end.split(":")[0], 10) + 24 - parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.end.split(":")[1], 10) - parseInt(task.start.split(":")[1], 10))) * 0.06944
                  var margin = deviceHeight / 24 * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 - 3)
                  if (margin < 0) {
                    margin = deviceHeight * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 + 21) / 24
                  }
                }

                else {
                  var height = ((parseInt(task.end.split(":")[0], 10) - parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.end.split(":")[1], 10) - parseInt(task.start.split(":")[1], 10))) * 0.06944
                  var margin = deviceHeight / 24 * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 - 3)
                  if (margin < 0) {
                    margin = deviceHeight * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 + 21) / 24
                  }
                }

                console.log(height, margin)
                let a = [...heightarray]
                a.push(height)
                setH(a)
                let b = [...marginarray]
                b.push(margin)
                setM(b)
                let c = [...titlearray]
                c.push(task.title)
                setT(c)

                console.log(a, b, c)
              }

              else if (mode == 'do') {



                day.id = date
                day.do.push(task)

                if (localStorage.getItem('days') == null) {
                  const obj = {}
                  obj[date] = day;
                  localStorage.setItem('days', JSON.stringify(obj))
                  localdays = obj;
                }

                else {

                  if (localdays[date] == null) {
                    localdays[date] = day;
                    localStorage.setItem('days', JSON.stringify(localdays))
                  }
                  else {
                    localdays[date].do.push(task)
                    localStorage.setItem('days', JSON.stringify(localdays))
                  }
                }

                if (parseInt(task.start.split(":")[0], 10) > 3 && parseInt(task.end.split(":")[0], 10) < 3) {
                  var height = ((parseInt(task.end.split(":")[0], 10) + 24 - parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.end.split(":")[1], 10) - parseInt(task.start.split(":")[1], 10))) * 0.06944
                  var margin = deviceHeight / 24 * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 - 3)
                  if (margin < 0) {
                    margin = deviceHeight * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 + 21) / 24
                  }
                }

                else {
                  var height = ((parseInt(task.end.split(":")[0], 10) - parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.end.split(":")[1], 10) - parseInt(task.start.split(":")[1], 10))) * 0.06944
                  var margin = deviceHeight / 24 * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 - 3)
                  if (margin < 0) {
                    margin = deviceHeight * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 + 21) / 24
                  }
                }
                let a = [...heightarray2]
                a.push(height)
                setH2(a)
                let b = [...marginarray2]
                b.push(margin)
                setM2(b)
                let c = [...titlearray2]
                c.push(task.title)
                setT2(c)
              }

              else if (mode == 'plan-modify') {

                localdays[date].plan[blockindex] = task

                localStorage.setItem('days', JSON.stringify(localdays))

                if (parseInt(task.start.split(":")[0], 10) > 3 && parseInt(task.end.split(":")[0], 10) < 3) {
                  var height = ((parseInt(task.end.split(":")[0], 10) + 24 - parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.end.split(":")[1], 10) - parseInt(task.start.split(":")[1], 10))) * 0.06944
                  var margin = deviceHeight / 24 * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 - 3)
                  if (margin < 0) {
                    margin = deviceHeight * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 + 21) / 24
                  }
                }

                else {
                  var height = ((parseInt(task.end.split(":")[0], 10) - parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.end.split(":")[1], 10) - parseInt(task.start.split(":")[1], 10))) * 0.06944
                  var margin = deviceHeight / 24 * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 - 3)
                  if (margin < 0) {
                    margin = deviceHeight * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 + 21) / 24
                  }
                }

                let a = [...heightarray]
                a[blockindex] = height
                setH(a)
                let b = [...marginarray]
                b[blockindex] = margin
                setM(b)
                let c = [...titlearray]
                c[blockindex] = task.title
                setT(c)

              }

              else if (mode == 'do-modify') {

                localdays[date].do[blockindex] = task

                localStorage.setItem('days', JSON.stringify(localdays))

                if (parseInt(task.start.split(":")[0], 10) > 3 && parseInt(task.end.split(":")[0], 10) < 3) {
                  var height = ((parseInt(task.end.split(":")[0], 10) + 24 - parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.end.split(":")[1], 10) - parseInt(task.start.split(":")[1], 10))) * 0.06944
                  var margin = deviceHeight / 24 * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 - 3)
                  if (margin < 0) {
                    margin = deviceHeight * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 + 21) / 24
                  }
                }

                else {
                  var height = ((parseInt(task.end.split(":")[0], 10) - parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.end.split(":")[1], 10) - parseInt(task.start.split(":")[1], 10))) * 0.06944
                  var margin = deviceHeight / 24 * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 - 3)
                  if (margin < 0) {
                    margin = deviceHeight * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 + 21) / 24
                  }
                }

                let a = [...heightarray2]
                a[blockindex] = height
                setH2(a)
                let b = [...marginarray2]
                b[blockindex] = margin
                setM2(b)
                let c = [...titlearray2]
                c[blockindex] = task.title
                setT2(c)

              }

              setTitle('')
              setMemo('')
              setStart('')
              setEnd('')
              setHash('')
              document.querySelector('.modal').classList.toggle('on-off')
            }

            else {
              if (parseInt(task.end.split(":")[0], 10) < 3) {
                if (mode == 'plan') {

                  console.log('플랜')
                  day.id = date
                  day.plan.push(task)

                  if (localStorage.getItem('days') == null) {
                    const obj = {}
                    obj[date] = day;
                    localStorage.setItem('days', JSON.stringify(obj))
                    localdays = obj;
                  }

                  else {

                    if (localdays[date] == null) {
                      localdays[date] = day;
                      localStorage.setItem('days', JSON.stringify(localdays))
                    }
                    else {
                      localdays[date].plan.push(task)
                      localStorage.setItem('days', JSON.stringify(localdays))
                    }
                  }


                  if (parseInt(task.start.split(":")[0], 10) > 3 && parseInt(task.end.split(":")[0], 10) < 3) {
                    var height = ((parseInt(task.end.split(":")[0], 10) + 24 - parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.end.split(":")[1], 10) - parseInt(task.start.split(":")[1], 10))) * 0.06944
                    var margin = deviceHeight / 24 * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 - 3)
                    if (margin < 0) {
                      margin = deviceHeight * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 + 21) / 24
                    }
                  }

                  else {
                    var height = ((parseInt(task.end.split(":")[0], 10) - parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.end.split(":")[1], 10) - parseInt(task.start.split(":")[1], 10))) * 0.06944
                    var margin = deviceHeight / 24 * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 - 3)
                    if (margin < 0) {
                      margin = deviceHeight * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 + 21) / 24
                    }
                  }

                  console.log(height, margin)
                  let a = [...heightarray]
                  a.push(height)
                  setH(a)
                  let b = [...marginarray]
                  b.push(margin)
                  setM(b)
                  let c = [...titlearray]
                  c.push(task.title)
                  setT(c)

                  console.log(a, b, c)
                }

                else if (mode == 'do') {



                  day.id = date
                  day.do.push(task)

                  if (localStorage.getItem('days') == null) {
                    const obj = {}
                    obj[date] = day;
                    localStorage.setItem('days', JSON.stringify(obj))
                    localdays = obj;
                  }

                  else {

                    if (localdays[date] == null) {
                      localdays[date] = day;
                      localStorage.setItem('days', JSON.stringify(localdays))
                    }
                    else {
                      localdays[date].do.push(task)
                      localStorage.setItem('days', JSON.stringify(localdays))
                    }
                  }

                  if (parseInt(task.start.split(":")[0], 10) > 3 && parseInt(task.end.split(":")[0], 10) < 3) {
                    var height = ((parseInt(task.end.split(":")[0], 10) + 24 - parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.end.split(":")[1], 10) - parseInt(task.start.split(":")[1], 10))) * 0.06944
                    var margin = deviceHeight / 24 * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 - 3)
                    if (margin < 0) {
                      margin = deviceHeight * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 + 21) / 24
                    }
                  }

                  else {
                    var height = ((parseInt(task.end.split(":")[0], 10) - parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.end.split(":")[1], 10) - parseInt(task.start.split(":")[1], 10))) * 0.06944
                    var margin = deviceHeight / 24 * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 - 3)
                    if (margin < 0) {
                      margin = deviceHeight * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 + 21) / 24
                    }
                  }
                  let a = [...heightarray2]
                  a.push(height)
                  setH2(a)
                  let b = [...marginarray2]
                  b.push(margin)
                  setM2(b)
                  let c = [...titlearray2]
                  c.push(task.title)
                  setT2(c)
                }

                else if (mode == 'plan-modify') {

                  localdays[date].plan[blockindex] = task

                  localStorage.setItem('days', JSON.stringify(localdays))

                  if (parseInt(task.start.split(":")[0], 10) > 3 && parseInt(task.end.split(":")[0], 10) < 3) {
                    var height = ((parseInt(task.end.split(":")[0], 10) + 24 - parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.end.split(":")[1], 10) - parseInt(task.start.split(":")[1], 10))) * 0.06944
                    var margin = deviceHeight / 24 * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 - 3)
                    if (margin < 0) {
                      margin = deviceHeight * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 + 21) / 24
                    }
                  }

                  else {
                    var height = ((parseInt(task.end.split(":")[0], 10) - parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.end.split(":")[1], 10) - parseInt(task.start.split(":")[1], 10))) * 0.06944
                    var margin = deviceHeight / 24 * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 - 3)
                    if (margin < 0) {
                      margin = deviceHeight * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 + 21) / 24
                    }
                  }

                  let a = [...heightarray]
                  a[blockindex] = height
                  setH(a)
                  let b = [...marginarray]
                  b[blockindex] = margin
                  setM(b)
                  let c = [...titlearray]
                  c[blockindex] = task.title
                  setT(c)

                }

                else if (mode == 'do-modify') {

                  localdays[date].do[blockindex] = task

                  localStorage.setItem('days', JSON.stringify(localdays))

                  if (parseInt(task.start.split(":")[0], 10) > 3 && parseInt(task.end.split(":")[0], 10) < 3) {
                    var height = ((parseInt(task.end.split(":")[0], 10) + 24 - parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.end.split(":")[1], 10) - parseInt(task.start.split(":")[1], 10))) * 0.06944
                    var margin = deviceHeight / 24 * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 - 3)
                    if (margin < 0) {
                      margin = deviceHeight * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 + 21) / 24
                    }
                  }

                  else {
                    var height = ((parseInt(task.end.split(":")[0], 10) - parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.end.split(":")[1], 10) - parseInt(task.start.split(":")[1], 10))) * 0.06944
                    var margin = deviceHeight / 24 * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 - 3)
                    if (margin < 0) {
                      margin = deviceHeight * (((parseInt(task.start.split(":")[0], 10)) * 60 + (parseInt(task.start.split(":")[1], 10))) / 60 + 21) / 24
                    }
                  }

                  let a = [...heightarray2]
                  a[blockindex] = height
                  setH2(a)
                  let b = [...marginarray2]
                  b[blockindex] = margin
                  setM2(b)
                  let c = [...titlearray2]
                  c[blockindex] = task.title
                  setT2(c)

                }

                setTitle('')
                setMemo('')
                setStart('')
                setEnd('')
                setHash('')
                document.querySelector('.modal').classList.toggle('on-off')
              }
              else {
                alert('시작시간이 종료시간보다 작습니다.')
              }
            }




            // }

          }}> {button} </button>
        </div>
      </div>

      <div>
        <input type='text' style={{ marginTop: deviceHeight * 1.1, width: '90%', minHeight: '300px', fontSize: '20px' }}
          onChange={(e) => { setBox(e.target.value) }} value={box}
        ></input>
      </div>
      <button 
      style={{ margin : '20px'}}
        onClick={() => {
          console.log(localdays[date])
          // 데이터가 있을 경우 (키값이 있을 경우)
          // 해당 키값의 밸류에 textbox: 문자열 형식으로 저장.
          // 로컬스토리지 저장
          // day.textbox = box

          localdays[date].textbox = box
          localStorage.setItem('days', JSON.stringify(localdays))

          // 데이터가 없을 경우
          // 일단 위에 하나 이상 만들어주세요 alert 띄우고 이후 수정.

          // 입력값 오버플로우 방지
          // 화면 새로고침 했을때 불러오기
        }}>저장하기</button>

    </>
  )
} 

function Bottom (props) {
  return(
    <div style={{
      width: props.width,
      height: deviceHeight,
      backgroundColor: props.color,
      display: 'flex',
      flexDirection: 'column'
    }}>
    </div>
  )
}


export default App;

